<template>
    <div class="main-box">
        <div class="main-box-header">
            <div class="header-title">
                <img src="@assets/image/COTC_logo.png" alt="">
                <span>编程学习笔记</span>
            </div>
            <el-menu
                :default-active="activeIndex"
                class="header-menu"
                mode="horizontal"
                @select="handleSelect"
            >
                <el-menu-item index="001">首页</el-menu-item>
                <el-submenu index="002">
                <template slot="title">前端技术</template>
                <el-menu-item index="002001">HTML</el-menu-item>
                <el-menu-item index="002002">JavaScript</el-menu-item>
                <el-menu-item index="002003">Css</el-menu-item>
                <el-submenu index="002004">
                    <template slot="title">框架</template>
                    <el-menu-item index="002004001">Vue</el-menu-item>
                    <el-menu-item index="002004002">React</el-menu-item>
                </el-submenu>
                </el-submenu>
                <el-menu-item index="003">热门语言</el-menu-item>
                <el-menu-item index="004">其他语言</el-menu-item>
                <el-menu-item index="005">网络与服务</el-menu-item>
                <el-menu-item index="006">数据库</el-menu-item>
                <el-menu-item index="007">其他知识</el-menu-item>
            </el-menu>
        </div>
        <div class="main-box-content">
            <div class="content-box">
                <div class="content-title">由浅入深的编程学习笔记</div>
                <p>本站记录的是前、后端编程及数据库开发技术的学习历程。</p>
                <p>每一段代码、每一个项目、每一个困难，都是编程生涯中成长的足迹。</p>
                <p>相信学习编程是一件既开心又有成就感的事情。</p>
            </div>
            <div class="grid-box">
                <div class="grid-list">
                    <div v-for="(item, index) in tableList" :key="index" class="grid-list-item" :class="gridIndex === index ? 'grid-list-item-active' : ''">
                        <span class="cursor" @click="clickHandler(item, index)">{{ item.title }}</span>
                    </div>
                </div>
                <div class="grid-box-describe">
                    <div class="describe-content">{{ describe }}</div>
                </div>
            </div>
        </div>
        <div class="main-box-foot">
            <span style="font-family:arial;">Copyright &copy;2024 ghostangel909.cn. All rights reserved.</span>
            <span>
                <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2024238515号-2</a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "homePage",
    data() {
        return {
            activeIndex: "001",
            describe: `计算机编程语言用于实现人与机器之间的交流和互动，按不同标准有多种不同分类，本站主要关注的是前端编程语言、后端编程语言和数据库编程语言，并对围绕此三类语言扩展的第三方框架进行深入研究。`,
            gridIndex: -1,
            tableList: [
                {
                    title: 'HTML',
                    describe: `HTML，即超文本标记语言（HyperText Markup Language），是用于创建网页的标准标记语言。它由一系列标签组成，可描述网页结构和内容，支持嵌入图像、音频、视频等多媒体元素。HTML文档由浏览器解析和渲染，主要目的是使网页具有结构和语义，便于搜索引擎和辅助工具理解。HTML5是其最新版本，引入了新元素和API，支持更丰富的交互和多媒体功能。`,
                }, {
                    title: 'HTML5',
                    describe: `HTML5革新了网页开发标准，强化了多媒体支持，如视频和音频的直接嵌入。通过语义化标签，HTML5提升了网页结构清晰度，有助于SEO和可访问性。同时，它实现了跨平台兼容，确保网页在各种设备和浏览器上都能一致呈现，推动了Web技术的进一步发展。`,
                }, {
                    title: 'JavaScript',
                    describe: `JavaScript是一种轻量级的解释型编程语言，广泛用于Web开发。它支持面向对象、命令式、声明式和函数式编程范式，能够创建动态网页内容和丰富的用户界面交互。JavaScript在客户端运行，可响应用户操作，实现动画效果、表单验证、异步请求等功能，是构建现代Web应用不可或缺的技术。`,
                }, {
                    title: 'jQuery',
                    describe: `jQuery是一个快速、简洁的JavaScript库，旨在简化HTML文档遍历、事件处理、动画效果制作和Ajax交互等操作。它提供了易于理解的语法和跨浏览器兼容性，使开发者能够更高效地编写JavaScript代码。jQuery拥有丰富的插件生态系统，可扩展其功能，广泛用于实现各种网页特效和交互功能。`,
                }, {
                    title: 'CSS',
                    describe: `CSS（层叠样式表）是用于描述HTML或XML文档外观的语言。它允许开发者控制网页的布局、颜色、字体等样式，使网页更加美观和用户友好。CSS通过选择器、属性和属性值来定义样式规则，可以内联、内部或外部引用。`,
                }, {
                    title: 'CSS3',
                    describe: `CSS3是层叠样式表（CSS）技术的升级版本，为Web开发者提供了更多样式和布局选项。它引入了新的选择器、盒模型、背景、边框、文字特效等模块，使网页设计更加灵活和丰富。CSS3还支持动画和过渡效果，为网页增添动态美感。其模块化的设计使得不同浏览器可以逐步支持新特性，推动了Web标准的进步。`,
                }, {
                    title: 'SCSS',
                    describe: `SCSS是Sass的一种语法，扩展了CSS的功能，引入了变量、嵌套规则、混合和继承等特性。这些特性使得样式表的编写更加简洁、有组织和可维护。SCSS语法类似于CSS，易于学习和使用。`,
                }, {
                    title: 'LESS',
                    describe: `LESS是一种CSS预处理语言，它扩展了CSS的功能，增加了变量、混合、函数等特性。LESS允许开发者编写更灵活、可维护的样式表，并支持在服务器端或客户端进行编译。`,
                }, {
                    title: 'Vue',
                    describe: `Vue.js（通常简称为Vue）是一个用于构建用户界面的渐进式JavaScript框架。它易于上手，同时提供了强大的功能，如数据绑定、组件系统和丰富的生态系统。Vue的核心库专注于视图层，并且非常容易与其他库或已有项目整合。`,
                }, {
                    title: 'React',
                    describe: `React是一个用于构建用户界面的JavaScript库，由Meta公司（前身为Facebook）开发。它以声明式设计、组件化和高效性为核心特点，允许开发者创建交互式UI，并通过对DOM的模拟最大限度地减少交互。React支持多种技术栈，且灵活易用，使得开发者能够轻松开发新功能，是现代Web开发领域的重要工具之一。`,
                }, {
                    title: 'uni-app',
                    describe: `uni-app是一个使用Vue.js开发所有前端应用的框架，它支持编译为H5、小程序、App等多个平台。uni-app提供了丰富的组件和API，使得开发者可以使用一套代码同时发布到多个平台。`,
                }, {
                    title: 'JSON',
                    describe: `JSON（JavaScript Object Notation）是一种轻量级的数据交换格式，易于阅读和编写。它基于文本，使用键值对表示数据，支持数组和对象等复杂数据结构。JSON与语言无关，可以与任何编程语言一起使用，广泛用于Web应用程序之间的数据传输。其简洁性和易用性使其成为数据存储、配置和数据交换的流行选择。`,
                }, {
                    title: 'XML',
                    describe: `XML是一种可扩展标记语言，用于描述结构化数据。它类似于HTML，但更注重数据存储和传输。XML文档具有树形结构，包含根元素、子元素和属性。它常用于数据交换、配置文件和Web服务中，因其自描述性和跨平台性而受到青睐。`,
                }, {
                    title: 'PHP',
                    describe: `PHP是一种开源的、服务器端脚本语言，适用于Web开发。PHP语法简洁，易于学习。它提供了丰富的内置函数和扩展库，支持多种数据库和文件系统操作。PHP在动态网页生成、Web应用程序开发和电子商务平台等领域有广泛应用。`,
                }, {
                    title: 'Node',
                    describe: `Node.js是一个基于Chrome V8引擎的JavaScript运行环境。它允许JavaScript代码在服务器端运行，支持异步I/O和非阻塞事件驱动模型。Node.js具有高性能、可扩展性和灵活性等特点，适用于构建实时Web应用、API服务和微服务架构等。`,
                }, {
                    title: 'Express',
                    describe: `Express是一个灵活且高效的Node.js Web应用框架，提供了丰富的HTTP工具。它基于中间件的概念，允许开发者编写可复用的代码片段来处理请求和响应。Express还支持多种路由和模板引擎，是构建Web应用和API的常用选择。`,
                }, {
                    title: 'HTTP',
                    describe: `HTTP是超文本传输协议，是互联网上应用最为广泛的一种网络协议。它定义了客户端与服务器之间数据传输的格式和规则，实现了超文本的快速、准确传输。HTTP协议具有简单快速、灵活、无连接和无状态等特点，支持客户/服务器模式，广泛应用于Web浏览、数据交换等领域。`,
                }, {
                    title: 'Nginx',
                    describe: `Nginx是一个高性能的HTTP和反向代理服务器，由俄罗斯程序员开发。它支持负载均衡、缓存、SSL/TLS终端代理、静态内容服务等多种功能，具有模块化、配置灵活、高并发处理能力和低资源消耗等特点。Nginx以其出色的性能和稳定性，成为现代Web基础设施中的重要组件。`,
                }, {
                    title: 'SQL',
                    describe: `SQL是结构化查询语言，用于操作和查询关系数据库。它包含数据定义、数据操作、数据查询和数据控制四个部分，语法简洁且一体化。SQL支持多种数据库系统，如Oracle、MySQL等，成为数据库交互的标准语言。`,
                }, {
                    title: 'MySQL',
                    describe: `MySQL是一个开源的关系型数据库管理系统，具有高性能、可扩展性和安全性等特点。它支持多用户并发访问，提供了丰富的编程接口和直观的管理工具。MySQL广泛应用于Web开发、企业级应用和数据分析等领域，是受欢迎的开源数据库之一。`,
                }, {
                    title: 'MongoDB',
                    describe: `MongoDB是一个基于分布式文件存储的面向文档的数据库，具有高性能、易部署、模式自由和强大的查询语言等特点。它支持自动处理分片，能够轻松应对数据量的增长和业务的扩展需求。MongoDB广泛应用于互联网应用、日志分析、地理信息系统等多种场景。`,
                }
            ]
        };
    },
    methods: {
        handleSelect() {
            // alert("敬请期待！");
            this.$message('敬请期待！');
        },
        clickHandler(item, index) {
            this.describe = item.describe;
            this.gridIndex = index;
        }
    }
};
</script>

<style lang="less" scoped>
.main-box {
    .main-box-header {
        display: flex;
        justify-content: center;
        align-items: center;
        .header-title {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            margin-right: 50px;
            img {
                height: 50px;
                margin-right: 12px;
            }
        }
    }
    .main-box-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .content-box {
            width: 800px;
            padding: 40px;
            .content-title {
                font-size: 32px;
                text-align: center;
                line-height: 48px;
                margin: 50px 0 30px;
            }
            p {
                font-size: 24px;
                text-align: center;
                line-height: 48px;
            }
        }
        .grid-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background: #eeeeee;
            .grid-list {
                width: 800px;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 20px;
                padding: 50px 0;
            }
            .grid-list-item {
                color: #000000;
            }
            .grid-list-item-active {
                color: #d88400;
            }
            .grid-box-describe {
                padding-bottom: 50px;
                .describe-content {
                    width: 800px;
                    font-size: 16px;
                    line-height: 32px;
                    border-right: 4px #EBE8CF solid;
                    border-left: 4px #EBE8CF solid;
                    border-radius: 20px;
                    padding: 5px 20px;
                }
            }
        }
    }
    .main-box-foot {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: auto;
        span {
            margin: 12px;
            a {
                color: #0085F2;
            }
        }
    }
}
.cursor {
    cursor: pointer;
}
</style>
