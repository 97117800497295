<template>
    <div class="main-box">
        <img src="@assets/image/404.gif" alt="">
    </div>
</template>

<script>
export default {
    name: 'notFound'
}
</script>

<style lang="less" scoped>
.main-box {
    display: flex;
    justify-content: center;
    height: 100vh;
    img {
        width: 100%;
    }
}
</style>