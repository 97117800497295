/*
 * @Description: 路由文件
 * @Author: WenwuLi
 * @Date: 2022-12-28 21:37:21
 * @LastEditors: 李文武
 * @LastEditTime: 2024-12-07 17:38:31
 */
// 配置路由相关信息
import VueRouter from 'vue-router';
import Vue from 'vue';
import routesData from './routes.js';

// 安装插件
Vue.use(VueRouter);


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法，捕获跳转相同路由的报错
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

// 创建路由对象
const routes = routesData.filter(item => item.path);

// 配置路由与组件间的映射关系
const router = new VueRouter({
    routes,
})

// 导出实例
export default router;