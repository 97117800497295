import homePage from '@/modules/home/index/index.vue';
import notFound from '@/modules/notFound/404/index.vue';

const routes = [
    {
        name: 'homePage',
        path: '/',
        component: homePage,
    }, {
        path: '*', // 通配符路由，匹配所有未定义的路径
        name: 'notFound',
        component: notFound
    }
];

export default routes;