/*
 * @Description: 入口文件
 * @Author: WenwuLi
 * @Date: 2022-12-04 22:41:11
 * @LastEditors: WenwuLi
 * @LastEditTime: 2023-01-07 22:23:14
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

// 重置标签样式
import './assets/css/reset.css';

// 引入elementUI框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 公共方法
import client from './common/js/client'
if (client) {
    Vue.prototype.$client = client;
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
